/* eslint-disable @typescript-eslint/unbound-method */
import { createSummaryRow } from '@/helpers/patientenUmsaetze-summary';
import { setStoreFromParams } from '@/helpers/patientenUmsaetze';
import router from '@/router';
import { metricsApi } from '@/services/data';
import { clientStateStore } from '@/state/clientStateStore';
import { createDirectStore } from 'direct-vuex';
import { Dictionary, filter, isEmpty } from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
import { last30Days, roseDayjs } from '../../../common-ui';
import {
  ITeam,
  PatientenUmsaetzeBezahltFilter,
  PatientenUmsaetzeGesamtoffenFilter,
  PatientenUmsaetzeMwstFilter,
  PrivatFilterTypeUI,
  IPatientenUmsaetzeExtended,
} from '../../../types';
import { viewStateStore } from '@/state/viewStateStore';
import { folgeTermineFilter, SpezialFilterHandler } from '@/helpers/spezialFilterHandler';

Vue.use(Vuex);

const { store, rootActionContext } = createDirectStore({
  state: () => ({
    patientenUmsaetze: [] as IPatientenUmsaetzeExtended[],
    patientenUmsaetzeLoading: false,
    patientSearchInput: '',
    dateEnabled: true,
    date: {
      startDate: roseDayjs(last30Days[0]).format('YYYY-MM-DD'),
      endDate: roseDayjs(last30Days[1]).format('YYYY-MM-DD'),
    },
    teamSelected: null as null | ITeam,
    leistungserbringerSelected: null as null | ITeam,
    summaryRow: [] as any[],
    extendedSummaryRow: [] as any[],
    privatFilter: PrivatFilterTypeUI.Alle as PrivatFilterTypeUI,
    gesamtOffenFilter: 'Alle' as PatientenUmsaetzeGesamtoffenFilter,
    bezahltFilter: 'Alle' as PatientenUmsaetzeBezahltFilter,
    mwstFilter: 'Alle' as PatientenUmsaetzeMwstFilter,
    spezialFilterHandler: new SpezialFilterHandler<IPatientenUmsaetzeExtended>({ ...folgeTermineFilter }),
  }),
  getters: {
    patientenUmsaetze(state) {
      return state.patientenUmsaetze;
    },
    patientenUmsaetzeFiltered(state) {
      let filtered = state.patientenUmsaetze.filter(l =>
        `${l.name} ${l.vorname} ${l.patid}`.toLowerCase().includes(state.patientSearchInput),
      );

      filtered = state.spezialFilterHandler.filterEntries(filtered);

      return filtered;
    },
    filterGroups(state) {
      return state.spezialFilterHandler.filterGroupsWithCounts(state.patientenUmsaetze);
    },
    patientenUmsatzeLoading(state) {
      return state.patientenUmsaetzeLoading;
    },
    patientSearchInput(state) {
      return state.patientSearchInput;
    },
    dateEnabled(state) {
      return state.dateEnabled;
    },
    date(state) {
      return state.date;
    },
    teamSelected(state) {
      return state.teamSelected;
    },
    leistungserbringerSelected(state) {
      return state.leistungserbringerSelected;
    },
    summaryRow(state) {
      return state.summaryRow;
    },
    extendedSummaryRow(state) {
      return state.extendedSummaryRow;
    },
    privatFilter(state) {
      return state.privatFilter;
    },
    gesamtOffenFilter(state) {
      return state.gesamtOffenFilter;
    },
    bezahltFilter(state) {
      return state.bezahltFilter;
    },
    mwstFilter(state) {
      return state.mwstFilter;
    },
  },
  mutations: {
    setPatientenumsatze(state, items: any[]) {
      state.patientenUmsaetze = items;
    },
    setPatientenumsatzeLoading(state, bool: boolean) {
      state.patientenUmsaetzeLoading = bool;
    },
    setPatientSearchInput(state, searchInput) {
      state.patientSearchInput = searchInput;
    },
    setDate(state, date) {
      state.date = date;
    },
    setDateEnabled(state, dateEnabled) {
      state.dateEnabled = dateEnabled;
    },
    setTeamSelected(state, team) {
      state.teamSelected = team;
    },
    setLeistungserbringerSelected(state, team) {
      state.leistungserbringerSelected = team;
    },
    setSummaryRow(state, summary) {
      state.summaryRow = summary;
    },
    setExtendedSummaryRow(state, summary) {
      state.extendedSummaryRow = summary;
    },
    setPrivatFilter(state, filt: PrivatFilterTypeUI) {
      state.privatFilter = filt;
    },
    setGesamtOffenFilter(state, filt: PatientenUmsaetzeGesamtoffenFilter) {
      state.gesamtOffenFilter = filt;
    },
    setBezahltFilter(state, filt: PatientenUmsaetzeBezahltFilter) {
      state.bezahltFilter = filt;
    },
    setMwstFilter(state, filt: PatientenUmsaetzeMwstFilter) {
      state.mwstFilter = filt;
    },
  },
  actions: {
    async getData(context, routeParams?: Dictionary<any>) {
      const { state, commit } = rootActionContext(context);

      commit.setPatientenumsatzeLoading(true);
      setStoreFromParams(routeParams);

      const queryParams = !isEmpty(routeParams)
        ? routeParams
        : {
            leistungsbehandler: state.leistungserbringerSelected?.id,
            stammbehandler: state.teamSelected?.id,
            from: state.dateEnabled ? state.date?.startDate : undefined,
            to: state.dateEnabled ? state.date?.endDate : undefined,
            privat: state.privatFilter !== PrivatFilterTypeUI.Alle ? state.privatFilter : undefined,
            gesamtoffen:
              state.gesamtOffenFilter !== PatientenUmsaetzeGesamtoffenFilter.Alle ? state.gesamtOffenFilter : undefined,
            bezahlt: state.bezahltFilter !== PatientenUmsaetzeBezahltFilter.Alle ? state.bezahltFilter : undefined,
            mwstFilter: state.mwstFilter !== PatientenUmsaetzeMwstFilter.Alle ? state.mwstFilter : undefined,
          };
      await router.push({ name: 'PatientenUmsaetze', query: queryParams });

      let patientenUmsaetze = await metricsApi.patientenUmsaetze.getPatientenUmsaetze(queryParams);

      await clientStateStore.getters.names.augment(patientenUmsaetze, undefined, { withPAR: true });
      await clientStateStore.getters.folgeterminInfo.augment(patientenUmsaetze);
      commit.setPatientenumsatze(filter(patientenUmsaetze, l => l.gesamt > 0));
      const summaryRow = createSummaryRow();
      commit.setSummaryRow([summaryRow]);
      commit.setPatientenumsatzeLoading(false);
      console.log('patiententumsätze', patientenUmsaetze, summaryRow);
    },
    async setPatientSearchInput(context, input: string) {
      const { dispatch, commit } = rootActionContext(context);

      await router.push({ name: 'PatientenUmsaetze', query: { patientSearchInput: input } });

      commit.setPatientSearchInput(input);

      await dispatch.getData();
    },
    async setDateEnabled(context, bool: boolean) {
      const { dispatch, commit } = rootActionContext(context);

      if (!bool) {
        await dispatch.setDate({ startDate: '', endDate: '' });
      } else {
        await dispatch.setDate({
          startDate: roseDayjs(last30Days[0]).format('YYYY-MM-DD'),
          endDate: roseDayjs(last30Days[1]).format('YYYY-MM-DD'),
        });
      }

      commit.setDateEnabled(bool);
    },
    async setDate(context, data: { startDate: string; endDate: string }) {
      const { dispatch, commit } = rootActionContext(context);

      commit.setDate(data);
      commit.setDateEnabled(true);

      await dispatch.getData();
    },
    async setTeamSelected(context, team: ITeam) {
      const { dispatch, commit } = rootActionContext(context);

      commit.setTeamSelected(team);

      await dispatch.getData();
    },
    async setLeistungserbringerSelected(context, team: ITeam) {
      const { dispatch, commit } = rootActionContext(context);

      commit.setLeistungserbringerSelected(team);

      await dispatch.getData();
    },
    async setPrivatFilter(context, filt: PrivatFilterTypeUI) {
      const { dispatch, commit } = rootActionContext(context);

      commit.setPrivatFilter(filt);

      await dispatch.getData();
    },
    async setGesamtOffenFilter(context, filt: PatientenUmsaetzeGesamtoffenFilter) {
      const { dispatch, commit } = rootActionContext(context);

      commit.setGesamtOffenFilter(filt);

      await dispatch.getData();
    },
    async setBezahltFilter(context, filt: PatientenUmsaetzeBezahltFilter) {
      const { dispatch, commit } = rootActionContext(context);

      commit.setBezahltFilter(filt);

      await dispatch.getData();
    },
    async setMwstFilter(context, filt: PatientenUmsaetzeMwstFilter) {
      const { dispatch, commit } = rootActionContext(context);

      commit.setMwstFilter(filt);

      await dispatch.getData();
    },
    async updateFilterViewState(context) {
      const { state } = rootActionContext(context);

      let viewStateString = state.spezialFilterHandler.getViewStateString();
      if (viewStateString) {
        await viewStateStore.dispatch.addToViewState({
          filters: viewStateString,
        });
      } else {
        await viewStateStore.dispatch.removeFromViewState(['filters']);
      }
    },
    setFilterStateFromViewState(context) {
      const { state } = rootActionContext(context);
      if (viewStateStore.getters.viewState.filters) {
        state.spezialFilterHandler.setFilterStateFromViewState(viewStateStore.getters.viewState.filters);
      }
    },
    async toggleFilterGroup(context, filterKey: string) {
      const { state, dispatch } = rootActionContext(context);

      state.spezialFilterHandler.toggleFilterGroup(filterKey);
      await dispatch.updateFilterViewState();
    },
    async filterGroupSelectedChanged(context, opts: { filterGroup: string; filterValue?: string }) {
      const { state, dispatch } = rootActionContext(context);

      state.spezialFilterHandler.filterGroupSelectedChanged(opts);

      await dispatch.updateFilterViewState();
    },
  },
});

export const patientenUmsaetzeStore = store;

// @ts-expect-error DEBUG
window.patientenUmsaetzeStore = patientenUmsaetzeStore;
