import { patientenUmsaetzeColumnDefs } from '@/components/columns/patientenUmsaetze';
import { patientenUmsaetzeStore } from '@/state/patientenUmsaetzeStore';
import { teamsStore } from '@/state/teamsStore';
import { Dictionary, map, reject, sortBy } from 'lodash';
import { boldCellStyle, createCell, createEmptyWorkSheet, finalizeSimpleXLSXWithMetadata } from '../../../base';
import { getUnitFromGrid, getValueFromGrid, IRoseAGGridColumn, roseDayjs } from '../../../common-ui';
import { createWorkBook, saveXLSX } from '../../../common-ui/src/directImports/exportExcel';
import { Units } from '../../../types';

export function setStoreFromParams(routeParams?: Dictionary<any>) {
  if (routeParams) {
    if (routeParams?.leistungsbehandler) {
      patientenUmsaetzeStore.commit.setLeistungserbringerSelected(
        teamsStore.getters.teams.find(t => t.id === routeParams.leistungsbehandler),
      );
    }
    if (routeParams?.stammbehandler) {
      patientenUmsaetzeStore.commit.setTeamSelected(
        teamsStore.getters.teams.find(t => t.id === routeParams.stammbehandler),
      );
    }
    if (routeParams?.patientSearchInput) {
      patientenUmsaetzeStore.commit.setPatientSearchInput(routeParams.patientSearchInput);
    }
    if (routeParams?.from && routeParams?.to) {
      patientenUmsaetzeStore.commit.setDate({
        startDate: routeParams.from,
        endDate: routeParams.to,
      });
      patientenUmsaetzeStore.commit.setDateEnabled(true);
    }
  }
}

export function downloadExcel() {
  let wb = createWorkBook();
  let ws = createEmptyWorkSheet(wb, 'PatientenUmsaetze');
  let exportColumns = reject(patientenUmsaetzeColumnDefs, cd => !!cd.noexport);

  function countNestingDepth(columnDef?: IRoseAGGridColumn<any>[]) {
    if (!columnDef) {
      return 0;
    }

    let max = 0;
    for (const cd of columnDef) {
      if (cd.children) {
        let maxChildren = countNestingDepth(cd.children);
        if (maxChildren > max) {
          max = maxChildren;
        }
      }
    }
    return max + 1;
  }

  let colCount = 0;
  let rowCount = 0;

  const maxNestingLevel = countNestingDepth(exportColumns);

  function printColumnHeaders(columnDef: IRoseAGGridColumn<any>) {
    let nestingDepth = countNestingDepth(columnDef.children);

    createCell(ws, colCount, maxNestingLevel - 1 - nestingDepth, columnDef.headerName, Units.NONE, boldCellStyle);

    if (nestingDepth === 0) {
      colCount++;
    }

    if (columnDef.children) {
      for (const child of columnDef.children) {
        printColumnHeaders(child);
      }
    }
  }

  exportColumns.forEach(child => {
    printColumnHeaders(child);
  });

  // write data
  rowCount = maxNestingLevel - 1;
  let sortedRowData = sortBy(patientenUmsaetzeStore.getters.patientenUmsaetzeFiltered, r => r.name);

  function collect<T>(array: T[], childKey: keyof T): T[] {
    return array
      .map(function (el) {
        if (childKey in el) {
          return collect(el[childKey] as unknown as T[], childKey);
        } else {
          return [el];
        }
      })
      .flat();
  }

  let leaves = collect(exportColumns, 'children');

  for (const row of sortedRowData) {
    colCount = 0;
    rowCount++;

    leaves.forEach(leave => {
      createCell(ws, colCount++, rowCount, getValueFromGrid(row, leave), getUnitFromGrid(leave));
    });
  }

  let metaData: any = {
    Filter: patientenUmsaetzeStore.getters.patientSearchInput || '-',
    Stammbehandler: patientenUmsaetzeStore.getters.teamSelected?.name || '-',
    Leistungerbringer: patientenUmsaetzeStore.getters.leistungserbringerSelected?.name || '-',
  };

  if (patientenUmsaetzeStore.getters.dateEnabled) {
    let selectedDateFormatted = `${roseDayjs(patientenUmsaetzeStore.getters.date.startDate).format(
      'DD.MM.YYYY',
    )} - ${roseDayjs(patientenUmsaetzeStore.getters.date.endDate).format('DD.MM.YYYY')}`;
    metaData['Datum-Filter'] = selectedDateFormatted;
  }

  finalizeSimpleXLSXWithMetadata(
    wb,
    ws,
    metaData,
    colCount,
    rowCount,
    map(exportColumns, c => ({ wpx: Math.trunc(c.exportWidth || c.width || 80) })),
  );
  saveXLSX(wb, 'patientenUmsaetze.xlsx');
}
